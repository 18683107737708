export  async function checkIsAdminLogin(url) {
    let isLogin = false;
    const token = localStorage.getItem("adminAuthKey")
    if (!token || token == null || token == undefined) return isLogin;

    isLogin = await verifyAdminToken(url, token)

    return isLogin;
}
export async function getAdminToken(username, password, authUrl) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "username": username,
        "password": password
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    const getTokenResp =  await fetch(authUrl, requestOptions);
    const getTokenJson = await getTokenResp.json();
    return {error: getTokenJson.data.trim(  ) == "Token Not Available" ,  token: getTokenJson.data}
        
}
export async function verifyAdminToken(url, token) {

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    const verifyTokenResp = await fetch(`${url}`, requestOptions)
    const isVerifiedObj = await verifyTokenResp.json()
    return isVerifiedObj.data
}