<template>
  <div class="main">
    <div>
      <img class="logo" src="../assets/img/logo.png" />
    </div>
    <br />
    <div>
      <h2>Algoji Admin Login</h2>
    </div>
    <form v-on:submit.prevent="authenticateFormSubmit">
      <div>
        <input
          v-model.lazy.trim="inputFields.username"
          type="text"
          name=""
          placeholder="Username"
          id=""
          class="input"
        /><!---->
      </div>
      <span v-if="validation.isNameError" class="errorText mb-10 text-danger"
              >You need to Enter Your Name</span
            >
      <div>
        <input
          type="password"
          name=""
          v-model.lazy.trim="inputFields.password"
          class="input"
          placeholder="Password"
          id=""
        />
        <span
          v-if="validation.isPasswordError"
          class="errorText mb-10 text-danger"
          >You need to Enter Correct Password</span
        >
      </div>

      <div>
        <div class="loginContainer">
          <button
            :disabled="!(isUserNameValid && isPasswordValid)"
            type="submit"
            class="loginBtn"
          >
            Login
          </button>
        </div>
        <div class="loginContainer"></div>
      </div>
    </form>
  </div>
</template>

<script>
import {getAdminToken} from "../hooks/checkAdminIsLogin";
export default {
  data() {
    return {
      inputFields: {
        username: "",
        password: "",
      },
      validation: {
        isPasswordError: false,
        isNameError:false
      },
    };
  },
  computed: {
    isUserNameValid() {
      const username = this.inputFields.username;
      if (username != undefined && username != null) {
        return username.trim() != "";
      }

      return false;
    },
    isPasswordValid() {
      const password = this.inputFields.password;
      if (password != undefined && password != null) {
        return password.trim() != "";
      }

      return false;
    },
  },
  methods: {
    getAdminToken,
    async authenticateFormSubmit(){
      if(!this.isUserNameValid || !this.isPasswordValid) return ;

      const userName = this.inputFields.username
      const password = this.inputFields.password;
      const authObj = await this.getAdminToken(userName , password , "https://emailclient.conveyor.cloud/api/Admin/Login")
      if(authObj.error){
        alert("Error")
        return ;
      }
      const {token} = authObj;
      localStorage.setItem("adminAuthKey" , `Bearer ${token}`) ;
      alert("Success");
      this.inputFields.username = ""
      this.inputFields.password = ""
    }
  },
};
</script>




<style>
.fetch_button {
  background: rgb(92, 148, 189);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  font-family: "ProximaNova Regular", sans-serif;
  padding: 8px 67px;
  cursor: pointer;
}
</style>
<style scoped>
.copyRightContainer {
  display: flex;
  justify-content: center;
}
.mb-10 {
  margin-bottom: 10px;
}
.copyRightContainer {
  font-size: 0.75rem;
  cursor: pointer;
}
.copyRightContainer :hover {
  text-decoration: underline;
}
@media (max-width: 400px) {
  .copyRightContainer {
    /* font-size: 0.7rem; */
    padding: 10px 2px;
  }
}
.linkStyleNone {
  text-decoration: none;
}
.row {
  flex-wrap: wrap;
  align-items: center;
  justify-items: flex-start;
}
.userDetailsDisplayContainerSpace {
  /* max-resolution: ; */
  /* width:100%; */
  margin: 20px;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
}
.loginContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.sendOtpBtn {
  margin-bottom: 1rem;
}
body,
html {
  display: block;
  box-sizing: border-box;
}
body {
  width: 100vw;
  padding: 0;
  margin: 0;
}
.input {
  min-width: 275px;
  overflow: hidden;
  border-radius: 25px;
  height: 35px;
  /* text-align: center; */
  margin-bottom: 20px;
  outline: none;
  border: 1;
  font-size: 16px;
  padding: 2px 20px;
  border: 1px solid grey;
}
.policyLinks {
  /* display: flex; */
  /* margin-right: 10rem; */
  /* justify-content: flex-end; */
  /* height: calc(100vh - 40%); */
  margin-top: 8rem;
  display: flex;
  /* min-height: 98vh; */
  align-items: flex-end;
  justify-content: flex-end;
  /* text-decoration: none; */
  color: black;
  /* position: absolute; */
  /* bottom: 0%; */
  /* right: 2%; */
}
.loginBtn {
  border-radius: 25px;
  height: 50px;
  text-align: center;
  display: block;
  padding: 0 20px;
  min-width: 125px;
  background-color: white;
  font-size: 18px;
  cursor: pointer;
  /* margin-right: 40px; */
  outline: none;
  border: 1px solid grey;
}
.rememberMe {
  padding-top: 5px;
  padding-bottom: 35px;
}

* {
  font-family: "Samim", sans-serif;
}
.logo {
  height: 135px;
}
.main {
  /* position: absolute; */
  /* top: 43%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  margin-top: 5rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;
}
.policyLink {
  color: black;
  font-weight: 500;
  margin-left: 15px;
}
textarea:focus,
input:focus,
button:focus {
  outline: none;
}
a {
  /* text-decoration: none; */
}
body {
  min-height: 100vh;
}
form label {
  font-size: 16px;
  margin: 0 1.2em;
  color: rgb(88, 88, 88);
}
@media only screen and (max-height: 600px) {
  .main {
    margin-top: 1rem;
  }
  .policyLinks {
    margin-top: 4rem;
  }
}
</style>

