(<template>
  <div>
      <div>
          <AdminLoginFormComponent />
      </div>
      <div>
          User List
      </div>
  </div>
</template>

<script>
import { URLS } from "../state/URLS.js";
// import "../assets/css/Common.css"
import AdminLoginFormComponent from "../components/AdminLoginFormComponent.vue";
export default {
    name:"AdminRootPage",
    components:{AdminLoginFormComponent},
    data(){
        return {
            URLS,

        }
    },
    
    mounted(){

    }
}
</script>

<style>

</style>